<template>
  <div class="landingWrapper">
    <div>
      <div class="background">
        <div class="city">
          <img
            style="min-width: 800px"
            src="@/assets/images/landing/main_illustration_city_silhouette.svg"
            alt=""
          />
        </div>
        <div class="wave">
          <img
            style="height: 100%; width: 100%; minwidth: 900px"
            src="@/assets/images/landing/main_wavy_background.svg"
            alt=""
          />
        </div>
        <div class="car animate animated" data-animation="leftRight" >
          <img
            src="@/assets/images/landing/main_illustration_reexport_1.png"
            alt=""
          />
        </div>
      </div>
      <div class="coverContent">
        <!-- <div class="car">
              <img
            src="@/assets/images/landing/main_illustration_car_platform.svg"
            alt=""
          />
          </div> -->
        <div class="text animate animated" data-animation="rightLeft" >
          <h1>
            VERIFICACIÓN <br />
            VEHICULAR <br />
            MÓVIL
          </h1>
          <p class="paragraph" style="font-size: 1.2rem;">
            La alternativa innovadora de medir las emisiones vehiculares y preservar el medio ambiente  
          </p>
          <a href="https://app.vvmovil.com"><button class="red">Agenda tu cita</button></a>
        </div>
      </div>
    </div>
    <div class="introWrapper">
      <!-- <h3>Blala</h3> -->
      <div class="introContent">
        <div class="stats">
          <div class="stats-container">
            <h2>Protección a tu Salud</h2>
            <p>
             En estos momentos, cuidar y preservar el medio ambiente se convierte en prioridad y compromiso de cada uno de los habitantes del planeta; la calidad del aire que respiramos es un elemento vital para proteger la salud de todos.
            </p>
            <p>
              En las metrópolis, los automóviles representan la principal fuente emisora de contaminantes del aire; controlar y disminuir las emisiones vehiculares, es hoy ya imperante y una responsabilidad medio ambiental y social.
            </p>
            <!-- <p>
              En las ciudades la principal fuente emisora de contaminantes son
              los vehículos y de allí el interés de todos nosotros por controlar
              y disminuir en lo posible las emisiones del parque vehicular.
            </p> -->
          </div>
          <div class="stats-container">
            <h2>Los vehículos representan aproximadamente el...</h2>
            <img
              src="@/assets/images/landing/conatminacion_percentages.svg"
              alt=""
            />
          </div>
        </div>
        <h1>
          Los gases contaminantes <br />
          y que se miden en esta prueba
        </h1>
      </div>
      <div class="emissionsWrapper">
        <div class="emissionsContent">
          <div class="emission animate" data-animation="downUp" data-animation-offset="350">
            <div class="header">
              <img
                class="title"
                src="@/assets/images/landing/emissions/CO_title.svg"
                alt=""
              />
              <img
                class="icon"
                src="@/assets/images/landing/emissions/CO_icon.svg"
                alt=""
              />
            </div>
            <div class="body">
              <p><strong>Monóxido de Carbono</strong></p>
              <p>
                Un gas sin olor ni color, que puede provocar intoxicación y en algunos casos hasta la muerte.
              </p>
            </div>
          </div>
          <div class="emission animate" data-animation="downUp" data-animation-delay="100" data-animation-offset="350">
            <div class="header">
              <img
                class="title"
                src="@/assets/images/landing/emissions/CO2_title.svg"
                alt=""
              />
              <img
                class="icon"
                src="@/assets/images/landing/emissions/CO2_icon.svg"
                alt=""
              />
            </div>
            <div class="body">
              <p><strong>Dióxido de Carbono</strong></p>
              <p>
                Necesario para la vida, pero en exceso dañino para la salud; es el gas que más contribuye al calentamiento global.

              </p>
            </div>
          </div>
          <div class="emission animate" data-animation="downUp" data-animation-delay="300" data-animation-offset="350">
            <div class="header">
              <img
                class="title"
                src="@/assets/images/landing/emissions/HC_title.svg"
                alt=""
              />
              <img
                class="icon"
                src="@/assets/images/landing/emissions/HC_icon.svg"
                alt=""
              />
            </div>
            <div class="body">
              <p><strong>Hidrocarburos</strong></p>
              <p>
                Capaces de contaminar aire, suelo y agua; su inhalación en exceso puede dañar los pulmones, afectar el sistema nervioso y causar daños neurológicos.
              </p>
            </div>
          </div>
          <div class="emission animate" data-animation="downUp" data-animation-delay="400" data-animation-offset="350">
            <div class="header">
              <img
                class="title"
                src="@/assets/images/landing/emissions/NOx_title.svg"
                alt=""
              />
              <img
                class="icon"
                src="@/assets/images/landing/emissions/NOx_icon.svg"
                alt=""
              />
            </div>
            <div class="body">
              <p><strong>Óxidos de Nitrógeno</strong></p>
              <p>
                Contaminantes atmosféricos, visibles como niebla que dañan ojos, nariz y garganta. Respirar altas cantidades puede causar quemaduras.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Landing",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.landingWrapper {
  .background {
    position: relative;
    z-index: -1;
    .city {
      position: absolute;
      width: 70%;
      right: 0;
      margin-top: 80px;
      overflow: hidden;
    }
    .wave {
      position: absolute;
      width: 100%;
      height: 400px;
      margin-top: 120px;
      @media screen and (max-width: 950px) {
        height: 350px;
      }
      @media screen and (max-width: 750px) {
        height: 250px;
      }
    }
    .car {
      position: absolute;
      width: 40%;
      left: 100px;
      min-width: 450px;
      img{
        width: 100%;
      }
      @media screen and (max-width: 850px) {
        left: 30px;
      }
      @media screen and (max-width: 740px) {
        left: 10px;
        min-width: 350px;
      }
      @media screen and (max-width: 550px) {
        left: -50px;
        min-width: 350px;
      }
      @media screen and (max-width: 430px) {
        left: -50px;
        min-width: 300px;
      }
    }
  }
  .coverContent {
    position: relative;
    margin: auto;
    width: 70vw;
    max-width: 1100px;
    text-align: right;
    z-index: 2;
    @media screen and (max-width: $mobile-width-1) {
      width: 90vw;
    }
    display: flex;
    justify-content: flex-end;
    .car {
      width: 55%;
      display: flex;
    }
    .text {
      width: 45%;
      @media screen and (max-width: 430px) {
        width: 100%;
      }
      h1 {
        @media screen and (max-width: 570px) {
          font-size: 1.5em;
        }
        // @media screen and (max-width: 425px) {
        //   font-size: 1.1em;
        // }
      }
    }
    .paragraph {
      margin-top: 70px;
      @media screen and (max-width: 430px) {
        margin-top: 90px;
      }
      max-width: 300px;
      color: $white-dirty;
      margin-left: auto;
    }
  }
  .introWrapper {
    position: relative;
    z-index: -5;
    background-image: url("../assets/images/landing/background_image_cut_L.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
    min-height: 250px;
    padding-top: 250px;
    @media screen and (max-width: 950px) {
      padding-top: 150px;
    }
    @media screen and (max-width: 750px) {
      // padding-top: 80px;
      margin-top: -70px;
    }
    .introContent {
      width: 70vw;
      max-width: 1100px;
      margin: auto;
      @media screen and (max-width: $mobile-width-1) {
        width: 90vw;
      }
      .stats {
        display: flex;
        justify-content: space-evenly;
        text-align: left;
        flex-wrap: wrap;
        .stats-container {
          position: relative;
          width: 250px;
          // border: double 4px transparent;
          border-radius: 30px;
          padding: 20px;
          margin-bottom: 30px;
          background-image: linear-gradient(
            to bottom,
            white,
            rgba(255, 255, 255, 0)
          );
          border: 2px solid $blue-light;
          // background-image: linear-gradient(white, rgba(255, 255, 255, 0)),
          // linear-gradient(-45deg, $white-dirty, $white-dirty, rgba(255, 215, 0, 0));
          // background-origin: border-box;
          // background-clip: padding-box, border-box;
        }
      }
    }
  }
  .emissionsWrapper {
    background-image: linear-gradient(to top, #f9f9f9, rgba(255, 255, 255, 0));
    .emissionsContent {
      width: 70vw;
      max-width: 1100px;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      @media screen and (max-width: $mobile-width-1) {
        width: 90vw;
      }
      .emission {
        width: 150px;
        background-color: $white-dirty;
        border-radius: 15px;
        border: 2px solid $blue-light;
        padding: 15px;
        text-align: left;
        margin: 10px;
        .header {
          border-bottom: 2px solid $blue-light;
          display: flex;
          justify-content: space-between;
          padding-bottom: 15px;
          .title {
            width: 50px;
          }
          .icon {
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
