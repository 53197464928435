import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from "vue-gtag";


// import axios from 'axios'
// import VueAxios from 'vue-axios'

Vue.config.productionTip = false

// Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: { id: "G-S887032BE8" }
});


new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')


