<template>
  <div class="headWrapper">
      <div class="headContent">
          <div class="realContent"> 
              <div class="logo">
              <router-link to="/"><img class="stand" src="@/assets/images/logo_menu.svg" alt=""></router-link>
              <router-link to="/"><img class="mobile" src="@/assets/images/logo_mobile.svg" alt=""></router-link>              
          </div>
          <div class="menuWrapper">
              <div class="line">

              </div>
              <div class="menu">
                <router-link class="menu-point" to="/">Home</router-link>
                <router-link to="/pruebas" class="menu-point">
                    Pruebas
                </router-link>
                <router-link to="/nosotros" class="menu-point">
                    Nosotros
                </router-link>
                <router-link to="/contacto" class="menu-point">
                    Contacto
                </router-link>
                <a href="https://app.vvmovil.com" class="menu-point cita">
                    Agenda tu cita
                </a>
                <div class="burger" @click="toggleSidebar()">
                    <img src="@/assets/images/hamburger_menu.svg" alt="">
                </div>
              </div>
              
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    toggleSidebar: function () {
      console.log('toggleing')
      document.getElementById("sidebar").classList.toggle("active");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
    .headWrapper{
        position: absolute;
        left: 0;
        top: 0;
        .headContent{
            
            width: 100vw;
            // max-width: 1100px;
            
            z-index: 12;
            height: 110px;
            transition: 0.5s;
            position: fixed;
            // padding: 0 15vw 0 15vw;
            top: 0;
            
            background-color: rgba(249, 249, 249, 0.7);
            .realContent{
                justify-content: space-between;
                align-items: center;
                width: 70%;
                max-width: 1100px;
                flex-direction: row;
                margin:auto;
                display: flex;
                height: 100%;
                 @media screen and (max-width: $mobile-width-1)  {
                    width: 90%;
                    // padding: 0 5vw 0 5vw;
                }
                .logo {
            width: 250px;
            margin-right: 40px;
            @media screen and (max-width: 360px)  {
                    width: 70%;
                }
            .stand{
width: 250px;
@media screen and (max-width: 360px)  {
                    display: none;
                }
            }
            .mobile{
                width: 100%;
                display: none;
                @media screen and (max-width: 360px)  {
                    display: block;
                }
            }
                // img {
                    
                // }
            }
            .menuWrapper{
                width: calc(100% - 290px);
                @media screen and (max-width: 360px)  {
                    width: 30%;
                }
                position: relative;
                .menu{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: fit-content;
                @media screen and (max-width: $mobile-width-2)  {
                    justify-content: flex-end;
                    }
                .menu-point{
                    text-transform: capitalize;
                    border-radius: 50px;
                    border: 2px solid $blue-light;
                    color: $blue-light;
                    width: 100px;
                    background-color: $white-dirty;
                    text-transform: none;
                    text-decoration: none;
                    height: 18px;
                    &.cita{
                        width: 120px;
                        background-color: $red;
                        border: 2px solid $white-dirty;
                        color: white;
                        padding: 0 5px;
                         height: 22px;
                    }
                    &.is-active{
                        border: 2px solid $white-dirty;
                        background-color: $blue-light;
                        color: $white-dirty;
                    }
                    @media screen and (max-width: $mobile-width-2)  {
                    display: none;
                    }
                }
                .burger{
                    display: none;
                    width: 50px;
                    // justify-self: flex-end;
                    @media screen and (max-width: $mobile-width-2)  {
                    display: block;
                        img{
                            width: 100%;
                        }
                    }
                }
                
            }
            .line{
                    width: 90%;
                    height: 2px;
                    background-color: $blue-light;
                    position: absolute;
                    top: 12px;
                    left: 5%;
                    z-index: -1;
                    @media screen and (max-width: $mobile-width-2)  {
                    display: none;
                    }
                }
            }
            }
            // @media screen and (max-width: $mobile-width-1)  {
            //     width: 90vw;
            //     padding: 0 5vw 0 5vw;
            // }
            
        }
    }
</style>
