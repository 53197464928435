import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pruebas',
    name: 'Pruebas',
    component: () => import('../views/Pruebas.vue')
  },
  {
    path: '/nosotros',
    name: 'Nosotros',
    component: () => import('../views/Nosotros.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/tips',
    name: 'Tips',
    component: () => import('../views/Tips.vue')
  },
  {
    path: '/limites',
    name: 'Limites',
    component: () => import('../views/Limites.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes
})

export default router
