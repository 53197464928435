<template>
  <div class="wrapper">
    <div class="content">
      <h1>¿Dónde estamos?</h1>
      <div class="map">
        <img style="width: 100%;" src="@/assets/images/landing/donde/map02.svg" alt="" />
      </div>
      <div class="text">
        <p>
          Ubícanos en el Norte de la República Mexicana y muy pronto en un punto cerca de tí.
        </p>
        <a href="https://app.vvmovil.com">
          <button class="red">Agenda tu cita</button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Donde",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content {
  position: relative;
  margin-top: 80px;
  .map {
    width: 70%;
     @media screen and (max-width: 600px) {
       margin: auto;
     }
  }
  .text {
    position: absolute;
    right: 0;
    top: 100px;
    width: 40%;
    @media screen and (max-width: 600px) {
        position: relative;
        top: 0;
        width: 100%;
        margin-bottom: 100px;
      }
  }
}
</style>
