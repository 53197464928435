<template>
  <div id="app">
    <Header/>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view class="router"/>
    <Footer/>
    <Sidebar />
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Sidebar from '@/components/Sidebar.vue';

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
      Sidebar
    },
    mounted() {
    window.addEventListener("scroll", function () {
      const animatedEls = document.getElementsByClassName('animate')
      const viewHeight = window.scrollY + window.innerHeight
      animatedEls.forEach( el =>{
        var animationOffset = 100
        const additionalOffset = el.getAttribute('data-animation-offset')
        if(additionalOffset) { animationOffset += (additionalOffset*1)}
        if(el.offsetTop < (viewHeight-animationOffset)) {
          el.classList.add('animated')
        }
      })
    })
    }
  }

</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap');
@import "@/assets/styles/variables"; 

#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

h1{
  color: $blue-dark;
  font-weight: 800;
}

h2{
  color: $blue-light  ;
}

button{
  background-color: $blue-dark;
  color: $white-dirty;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  &.red{
    background-color: $red;
  }
}

.wrapper{
        .content{
            width: 70vw;
            max-width: 1100px;
            margin: auto;
            @media screen and (max-width: $mobile-width-1)  {
                width: 90vw;
            }
        }
    }

.router-btn:focus{
  outline: none;
  cursor: pointer;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.animate{
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.animated{
  opacity: 1;
  visibility: visible;
  &[data-animation="downUp"]{
    animation: downUp 0.5s;
    opacity: 1;
  }
  &[data-animation="leftRight"]{
    animation: leftRight 0.5s;
    opacity: 1;
  }
  &[data-animation="rightLeft"]{
    animation: rightLeft 0.5s;
    opacity: 1;
  }
  &[data-animation-delay="100"]{
    animation-delay: 100ms;
    transition-delay: 100ms;
  }
  &[data-animation-delay="200"]{
    animation-delay: 200ms;
    transition-delay: 200ms;
  }
  &[data-animation-delay="300"]{
    animation-delay: 300ms;
    transition-delay: 300ms;
  }
  &[data-animation-delay="400"]{
    animation-delay: 400ms;
    transition-delay: 400ms;
  }
}

@keyframes downUp {
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes leftRight {
  0% { transform: translate3d(-200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes rightLeft {
  0% { transform: translate3d(200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}



.router{
  padding-top: 120px;
  width: 100vw;
}

</style>
