<template>
  <div class="wrapper">
    <div class="content">
      <div>
        <h1>¿Cómo funciona el proceso?</h1>
        <div class="steps">
          <div class="step animate" data-animation="downUp">
            <div class="number">
              <img src="@/assets/images/landing/process/1.svg" alt="" />
            </div>
            <div class="text">
              Agenda una cita y paga en nuestro sitio <a href="https://app.vvmovil.com/inicio" target="_blank">app.vvmovil.com</a> 
            </div>
            <div class="illustration">
              <img
                src="@/assets/images/landing/process/solicitud_de_cita.svg"
                alt=""
              />
            </div>
          </div>
          <div class="step animate" data-animation="downUp">
            <div class="number">
              <img src="@/assets/images/landing/process/2.svg" alt="" />
            </div>
            <div class="text">
              Recibe tu confirmación por correo electrónico.
            </div>
            <div class="illustration">
              <img
                src="@/assets/images/landing/process/confirmacion.svg"
                alt=""
              />
            </div>
          </div>
          <div class="step animate" data-animation="downUp">
            <div class="number">
              <img src="@/assets/images/landing/process/3.svg" alt="" />
            </div>
            <div class="text">Preséntate en la ubicación seleccionada para llevar a cabo la prueba.</div>
            <div class="illustration">
              <img
                src="@/assets/images/landing/process/presentacion_en_el_sitio.svg"
                alt=""
              />
            </div>
          </div>
          <div class="step animate" data-animation="downUp">
            <div class="number">
              <img src="@/assets/images/landing/process/4.svg" alt="" />
            </div>
            <div class="text">Realización de la prueba en 15 minutos.</div>
            <div class="illustration">
              <img
                src="@/assets/images/landing/process/prueba_15min.svg"
                alt=""
              />
            </div>
          </div>
          <div class="step animate" data-animation="downUp">
            <div class="number">
              <img src="@/assets/images/landing/process/5.svg" alt="" />
            </div>
            <div class="text">
              Obtención de resultados y recomendaciones. 
              <br> En caso positivo, se obtiene holograma.
              
              <!-- En caso <strong>positivo</strong>, se obtiene holograma, en el caso <strong>negativo</strong> se obtiene recomendaciones -->
              <!-- Obtención de resultados: <br> -->
              <!-- <span style="font-size: 0.8rem;">
                <strong>Positivo:</strong> obtención de holograma y certificado con resultados <br>
              <strong>Negativo:</strong> obtención de resultados y recomendaciones
              </span> -->
            </div>
            <div class="illustration">
              <img src="@/assets/images/landing/process/holograma.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Process",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
  @media screen and (max-width: 550px) {
      margin-top: -160px;
    }
    @media screen and (max-width: 800px) {
      margin-top: -100px;
    }
  .steps {
  margin: auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  .step {
    display: flex;
    align-items: center;
    // flex-wrap: wrap;
    margin: 20px 0;
    cursor: default;
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: 550px) {
      flex-direction: column;
      width: 80%;
    }
    .number {
      width: 70px;
    }
    .text {
      width: 200px;
      margin: 0 20px;
      text-align: left;
      @media screen and (max-width: 550px) {
        margin: 15px 0;
        width: 100%;
      }
    }
    .illustration {
      width: 100px;
      @media screen and (max-width: 550px) {
        width: 50%;
      }
    }
    &:hover{
      transform: scale(1.1);
    }
  }
}
}
</style>
