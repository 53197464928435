<template>
  <div class="wrapper">
      <div class="content">
          <div class="menu">
              <div class="logo">
                  <img src="@/assets/images/logo_footer.svg" alt="">
              </div>
              <div class="menu-links">
                  <div><router-link to="/">HOME</router-link></div>
                  <!-- <div><router-link to="/">FAQ</router-link></div> -->
                  <div><a href="https://drive.google.com/file/d/1OoJvz6ui77osb2o42uQtt6eSrUWLWqsX/view?usp=sharing" target="_blank">AVISO DE PRIVACIDAD</a></div>
                  <div><router-link to="/contacto">CONTACTO</router-link></div>
              </div>
          </div>
          <div style="margin-top:30px;">
              © 2021 VVMovil S.L.
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables"; 
    .wrapper{
        background-color: $blue-light;
        z-index: 10;
        .content{
            color: white;
            padding-bottom: 30px;
            .menu{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 60px;
                .logo{  
                    width: 30vw;
                }
                .menu-links{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    a{
                        color: white;
                        text-decoration: none;
                    }
                    a:visited{
color: white;
                    }
                }
            }
            
        }
    }
</style>
