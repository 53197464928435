<template>
  <div class="wrapper">
    <div class="wavy">
      <img
        src="@/assets/images/landing/verification/blue_wavy_background_middle.svg"
        alt=""
      />
    </div>
    <div class="content">
      <div>
        <h1 class="title">¿Qué es la Verificación Vehicular Móvil?</h1>
        <p class="text">
          La alternativa móvil a la verificación vehicular: es un mecanismo
          ágil, idóneo, inteligente y seguro para facilitar a los usuarios la
          medición de los gases contaminantes vehiculares que dañan al medio
          ambiente. Su utilización ayudará a prevenir, reducir e interrumpir el
          deterioro del aire que respiramos.
        </p>
        <p class="text">
          Nuestro equipo cuenta con tecnología de punta alemana y con un sistema confiable, robusto y seguro. Esto nos convierte en el único equipo móvil que no requiere constantes calibraciones y se encuentra debidamente certificado ante autoridades nacionales e internacionales.
        </p>
      </div>
      <div class="badge">
        <img
          src="@/assets/images/landing/verification/verification_badge.svg"
          alt=""
        />
      </div>
      <div>
        <img
          src="@/assets/images/landing/verification/carplatfrom_and_laptop.svg"
          alt=""
        />
      </div>
      <div class="leftTextWrap">
        <p class="text02">
          Las pruebas son aplicables para cualquier vehículo y todo tipo de combustibles.
        </p>
        <p class="text02">
          Haz tu prueba en 15 minutos y contribuye al medio ambiente.
        </p>
        <a href="https://app.vvmovil.com">
          <button class="red">Agenda tu cita</button>
        </a>
      </div>
      <div>
        <!-- <?xml version="1.0" encoding="utf-8"?> -->
        <!-- Generator: Adobe Illustrator 16.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <!-- <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 1895.529 2267.546" enable-background="new 0 0 1895.529 2267.546"
	xml:space="preserve">
<g id="SECTION_x5F_Que_es_la_VVM_x3F_">
	<path fill="#00B5E2" d="M959.536,1893.22C602.622,1761.607,252.542,1806.883,0,1872.574C0,1334.337,0,796.101,0,257.864
		c755.201-229.306,1027.487-138.087,1133.808-6.26c115.566,143.29-4.442,284.284,109.444,583.256
		c145.398,381.694,425.104,372.306,527.055,721.512c72.059,246.817,24.966,568.815-128.576,639.69
		C1483.501,2269.102,1317.524,2025.228,959.536,1893.22z"/>
	<rect x="249.682" y="301.239" fill="none" width="840.774" height="168.658"/>
        <text transform="matrix(1 0 0 1 249.6821 580.084)" fill="#F9F9F9" font-size="72">
        Verificación Vehicular
        </text>
	<rect x="249.682" y="556.812" fill="none" width="821.106" height="144.207"/>
	<text transform="matrix(1 0 0 1 249.6821 580.084)"><tspan x="0" y="0" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">La verificación vehicular tiene como objetivo proteger </tspan><tspan x="0" y="42" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">al medio ambiente a traves de la reducción de </tspan><tspan x="0" y="84" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">emisiones de los gases escape.</tspan></text>
	<g>
		<rect x="249.682" y="774.361" fill="none" width="809.362" height="200.524"/>
		<text transform="matrix(1 0 0 1 249.6821 797.6338)"><tspan x="0" y="0" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">For this to achieve it is important to maintain the </tspan><tspan x="0" y="42" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">emission standards of our vehicles. VV Móvil brings a </tspan><tspan x="0" y="84" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">practical alternative with a mobile car verification </tspan><tspan x="0" y="126" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">station and application, etc, etc.</tspan></text>
	</g>
	<g>
		<rect x="885.447" y="1538.55" fill="none" width="784.128" height="345.067"/>
		<text transform="matrix(1 0 0 1 946.1958 1603.8228)"><tspan x="0" y="0" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">Here something about the analytics and results, </tspan><tspan x="7.635" y="42" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">why its important, how it aligns to govermential </tspan><tspan x="480.428" y="84" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">regulations, etc.</tspan><tspan x="-57.928" y="168" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">As the last sentence, </tspan><tspan x="268.16" y="168" fill="#FFFFFF" font-family="'Inter-Black'" font-size="32">mention that both petrol and </tspan><tspan x="14.438" y="210" fill="#FFFFFF" font-family="'Inter-Black'" font-size="32">diesel cars can be verified</tspan><tspan x="431.791" y="210" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32"> = button directs to </tspan><tspan x="435.881" y="252" fill="#FFFFFF" font-family="'Inter-Regular'" font-size="32">„pruebas“ subpage</tspan></text>
	</g>
	<g id="button_x5F_tipos_de_pruebas">
		<path fill="#0088AA" d="M1634.329,2025.024h-270c-19.33,0-35-15.67-35-35l0,0c0-19.33,15.67-35,35-35h270c19.33,0,35,15.67,35,35
			l0,0C1669.329,2009.354,1653.659,2025.024,1634.329,2025.024z"/>
		<text transform="matrix(1.0743 0 0 1 1370.9028 1999.3462)" fill="#EDF3F4" font-family="'Inter-Medium'" font-size="24.0822">tipos de pruebas</text>
	</g>
</g>
</svg> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Verify",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/variables";
.wrapper {
  text-align: left;
  position: relative;
  overflow: hidden;
  // background-image: url('../assets/images/landing/verification/blue_wavy_background_middle.svg');
  // background-repeat: no-repeat;
  // background-size: cover;
  .wavy {
    width: 90%;
    height: 1000px;
    position: absolute;
    @media screen and (max-width: $mobile-width-1) {
      width: 100%;
    }
    img {
      height: 100%;
      width: 100%;
      min-width: 830px;
    }
  }
  .content {
    position: relative;
    margin: auto;
    min-height: 1000px;
    // left: 15vw;
    // @media screen and (max-width: $mobile-width-1) {
    //   left: 5vw;
    // }
    padding-top: 80px;
    .title {
      width: 55%;
      color: white;
      @media screen and (max-width: $mobile-width-1) {
        width: 65%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }
    .text {
      width: 55%;
      color: white;
      @media screen and (max-width: $mobile-width-1) {
        width: 65%;
      }
      @media screen and (max-width: 650px) {
        width: 85%;
      }
      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
    .leftTextWrap {
      margin-left: auto;
      text-align: right;
      width: 40%;
      @media screen and (max-width: $mobile-width-1) {
        width: 60%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
        text-align: center;
      }
      .text02 {
        color: white;
      }
    }

    top: 0;
    // width: 100vw;
    .badge {
      height: 250px;
      width: 150px;
      position: absolute;
      right: 0px;
      top: 100px;
      @media screen and (max-width: 740px) {
        display: none;
      }
    }
  }
  .wave {
    width: 70vw;
    .text {
      font-family: Inter;
    }
  }
}
</style>
