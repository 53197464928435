<template>
  <div class="sidebarWrapper" id="sidebar">
    <div style="padding: 0 30px">
      <!-- <h1 style="text-align: left" v-on:click="toggleSidebar()">&times;</h1> -->
      <div class="close" v-on:click="toggleSidebar()"><i class="fas fa-times"></i></div>
      <div class="menu">
        <img style="width: 90%; max-width: 500px;" src="@/assets/images/logo_white.svg" alt="">
        <router-link to="/"
          ><h2 v-on:click="toggleSidebar()">Home</h2></router-link
        >

        <router-link to="/pruebas"
          ><h2 v-on:click="toggleSidebar()">Pruebas</h2></router-link
        >

        <router-link to="/nosotros"
          ><h2 v-on:click="toggleSidebar()">Nosotros</h2></router-link
        >

        <router-link to="/contacto"
          ><h2 v-on:click="toggleSidebar()">Contacto</h2></router-link
        >
        <a class="cita" href="https://app.vvmovil.com">Agenda tu cita</a>
      </div>
      <!-- <div style="position:relative; right:0; width: 120%;">
        <img src="@/assets/images/landing/main_illustration_car_platform.svg" alt="">
      </div> -->
      
    </div>
    <div style="position: fixed; bottom: -5px; width: 100vw;">
          <img style="width: 100%;" src="@/assets/images/landing/main_illustration_city_silhouette.svg" alt="">
      </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  //   data() {
  //   return {
  //     frontendURL: process.env.VUE_APP_FRONTEND_BASE
  //   }
  // },

  methods: {
    toggleSidebar: function () {
      document.getElementById("sidebar").classList.toggle("active");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";
.sidebarWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 100vw;
  top: 0;
  background-image: linear-gradient(160deg, $blue 50%, $blue-light);
  transition: all 0.5s;
  z-index: 100;
  &.active {
    left: 0;
  }
  .close{
   width:50px; 
   height:50px; 
   background-color:white;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 35px;
   position: relative;
    right: 10px;
    float: right;
   i{
     color: $blue;
     font-size: 1.1rem;
   }
  }
  .menu {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding-top: 100px;
    // height: 50%;
    img {
      height: 100px;
    }
    h2 {
      margin: 30px 0;
      color: white;
      font-size: 1.8rem;
    }
    a{
      text-decoration: none;
    }
    .cita{
        background-color: $red;
        padding: 10px 30px;
        border-radius: 30px;
        margin: 30px 0;
        color: white;
        font-size: 1.8rem;
        font-weight: bold;
    }

  }

}
</style>
