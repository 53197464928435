<template>
  <div class="home">
    <Landing/>
    <Verify/>
    <Process/>
    <Donde/>
  </div>
</template>

<script>
// @ is an alias to /src
import Landing from '@/components/Landing.vue'
import Verify from '@/components/Verificacion.vue'
import Process from '@/components/Process.vue'
import Donde from '@/components/Donde.vue'


export default {
  name: 'Home',
  components: {
    Landing,
    Verify,
    Process,
    Donde
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>
